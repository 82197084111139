import "trix";
import Turbolinks from "turbolinks";
import "@rails/actiontext";
import "controllers";

const init = () => {
  Turbolinks.start();
};

document.addEventListener("turbolinks:load", init);
