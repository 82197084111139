import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list", "input", "form"];

  connect() {
    this.listTarget.scrollTo(0, this.listTarget.scrollHeight);

    this.inputTarget.onkeypress = (e) => {
      // Submit on return
      if (e.keyCode === 13 && e.shiftKey) {
        this.formTarget.submit();
        return false;
      }
    };
  }
}
